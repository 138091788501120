<template>
 <body class="flex items-center justify-center">
  <table
   class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed relative"
  >
   <thead class="bg-gray-50 divide-y divide-gray-200">
    <tr
     class="flex flex-col flex-no-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
     v-show="this.tableDataExtensions.length > 0"
    >
     <th
      scope="col"
      class="p-1 uppercase text-xs"
      v-for="header in this.tableHeaders"
      :key="header"
      :title="header"
     >
      <div
       @click="setSorting(header.key)"
       class="inline-flex lg:mt-0 xl:mt-0 sm:mt-0 cursor-pointer"
      >
       <span>
        {{ $t(header.name) }}
       </span>
       <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg> -->
      </div>
     </th>
     <th>
      <div class="inline-flex lg:mt-0 xl:mt-0 sm:mt-0 p-1 uppercase text-xs cursor-pointer">
       <span> {{ $t("avgRingingDuration") }} </span>
      </div>
     </th>
     <th>
      <div class="inline-flex lg:mt-0 xl:mt-0 sm:mt-0 p-1 uppercase text-xs cursor-pointer">
       <span> {{ $t("avgTalkingDuration") }} </span>
      </div>
     </th>
     <th v-for="header in this.tableKeys" :key="header">
      <div class="inline-flex lg:mt-0 xl:mt-0 sm:mt-0 p-1 uppercase text-xs cursor-pointer">
       <span>
        {{
         $tc(header.split("_")[0], header.split("_")[1] ? 2 : 1, { seconds: header.split("_")[1] })
        }}
       </span>
      </div>
     </th>
    </tr>
   </thead>
   <tbody
    v-show="this.tableDataExtensions.length > 0"
    class="flex-1 sm:flex-none divide-y divide-gray-200 text-center text-sm"
   >
    <tr
     v-for="data in this.tableData"
     :key="data.id"
     :class="[
      'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
     ]"
    >
     <td class="p-3">{{ data.extension }} - {{ data.agent ? data.agent : "" }}</td>
     <td class="p-3">
      {{ data.calls }}
     </td>
     <td class="p-3">
      {{ formatSeconds(data.avg_ringing_duration) }}
     </td>
     <td class="p-3">
      {{ formatSeconds(data.avg_talking_duration) }}
     </td>
     <td v-for="header in tableKeys" :key="header" class="p-3">
      {{
       (header.includes("talking") || header.includes("ringing")) && data["calls"]
        ? data[header] + `(${getPercentageValue(data["calls"], data[header])}%)`
        : data[header]
      }}
     </td>
    </tr>
   </tbody>
  </table>
 </body>
</template>

<script>
import { mapGetters } from "vuex";
import { Switch } from "@headlessui/vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["account", "tableData", "tableDataExtensions", "enabled", "tableKeys"],
 components: {
  Loading,
  Switch,
 },
 data() {
  return {
   search: "",
   isLoading: true,
   fullPage: false,
   activeSorting: "",
   tableHeaders: [
    {
     name: "extension",
     key: "extension",
     sort: "extension",
     isSort: false,
     description: this.$t("extension"),
    },
    {
     name: "calls",
     key: "calls",
     sort: "calls",
     isSort: false,
     description: this.$t("calls"),
    },
   ],
  };
 },
 methods: {
  formatSeconds(value) {
   var minutes = Math.floor(value / 60);
   var seconds = value - minutes * 60;
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
  splitGlobalNames(str) {
   let splitName = str.split(",");
   splitName.shift();
   return splitName;
  },
  getPercentageValue(total, value) {
   return ((parseInt(value) / parseInt(total)) * 100).toFixed(0);
  },
  getAverageTime(time, calls) {
   let avg = undefined;
   if (calls > 0) {
    avg = time / calls;
    if (avg > 0) {
     avg = this.timeFormater(avg);
    }
   } else {
    avg = 0;
   }
   return avg;
  },
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  setSorting(sort) {
   this.$emit("sort", sort);
  },
 },
 mounted() {
  console.log("tablePaginatedData", this.tablePaginatedData);
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
